import React from 'react';
import styled from 'styled-components'

const Pill = styled.button`
    background: gray;
    color: white;
    margin: 0 4px;
    border-radius: 12px;
    font-family: sans-serif;
`
const TitleBar = styled.h4`
    font-family: sans-serif;
    letter-spacing: normal;
`

const DisplayTag = ({ group, clickHandler }) => {
    return (
        <div className='tags'>
            <TitleBar>Tags
            {group.map(tag => (<Pill
                onClick={clickHandler}
                key={tag.fieldValue}>{tag.fieldValue} ({tag.totalCount})</Pill>
            ))}
            </TitleBar>
        </div>
    )
}

export default DisplayTag