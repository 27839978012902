import React from 'react';
import InfiniteImages from './InfiniteImages.tsx';

const WorksSection = ({ edges }) => (<InfiniteImages
    images={edges.map(({ node }: any) => ({
        id: node.image.id,
        ...node.image.childImageSharp.fluid,
        caption: node.title,
        tags: node.tags,
        thumbnail: node.thumbnail,
    }))}
    itemsPerRow={[2, 3]}

/>
);

export default WorksSection;
