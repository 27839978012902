import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Carousel, { Modal, ModalGateway } from 'react-images';
import carouselFormatters from '../utils/carouselFormatters';
import './gallery.css'
interface Props {
    images: {
        id: string;
        aspectRatio: number;
        src: string;
        srcSet: string;
        sizes: string;
        originalImg: string;
        caption: string;
    }[];
}

const ImageGallery = ({ images, setModalCurrentIndex, setModalIsOpen }: Props) => {
    const openModal = (imageIndex) => {
        setModalCurrentIndex(imageIndex);
        setModalIsOpen(true);
    };

    if (!images) return <p>no images found</p>
    return (
        <div className="image-grid">
            {images.map((image, index) => (
                <div className="image-item" key={image.id + index} onClick={() => openModal(index)} >
                    <img src={images[index].thumbnail.childImageSharp.fluid.originalImg} alt={image.caption} />
                    <h3>{image.caption}</h3>
                </div>
            ))}
        </div>
    )
}

const InfiniteImages = ({ images }) => {
    // Hold state
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

    const closeModal = () => setModalIsOpen(false);

    return (<div>
        <ImageGallery images={images}
            setModalCurrentIndex={setModalCurrentIndex}
            setModalIsOpen={setModalIsOpen} />
        {ModalGateway && (
            <ModalGateway>
                {modalIsOpen && (
                    <Modal
                        styles={{
                            dialog: base => ({
                                ...base,
                                marginTop: 80,
                            }),
                        }}
                        onClose={closeModal}>
                        <Carousel
                            views={images.map(({ src, caption }) => ({
                                source: src,
                                caption: caption
                            }))}
                            currentIndex={modalCurrentIndex}
                            formatters={carouselFormatters}
                            components={{ FooterCount: () => null }}
                        />
                    </Modal>
                )}
            </ModalGateway>
        )}
    </div>
    )
}

ImageGallery.propTypes = {
    images: PropTypes.array,
}

export default InfiniteImages
