import React, { useState } from 'react';
import Layout from '../components/layout';
// Gatsby supports TypeScript natively!
import { PageProps, Link, graphql } from "gatsby"

import WorksSection from '../components/WorksSection';
import DisplayTag from '../components/DisplayTag';
import PropTypes from "prop-types"

const TagsPage = ({
    data: {
        allWorksYaml: { edges, group },
        site: {
            siteMetadata: { title },
        },
    },
}) => {
    const [filter, setFilter] = useState('')
    const handleClick = (e) => {
        if (!e.target || !e.target.textContent) console.log('nothing selected');
        const str = e.target.textContent;
        const tag = str.substr(0, str.indexOf(' ('))
        setFilter(tag);
    }

    let arr = filter ? edges.filter((elem) => elem.node.tags.includes(filter)).reverse() : edges.reverse() || [];
    return (
        <div>
            <Layout title={title}>
                <form action="https://tinyletter.com/badaboot" method="post" target="popupwindow" onSubmit="window.open('https://tinyletter.com/badaboot', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true">
                    <p style={{
                        textAlign: 'center',
                        fontFamily: 'sans-serif',
                        marginTop: 16,
                        marginBottom: -28
                    }}><label htmlFor="tlemail">Subscribe to get the latest comics!</label><input style={{
                        marginRight: 10,
                        marginLeft: 10,
                        borderRadius: 4,
                    }} type="text" placeholder="Email address" name="email" id="tlemail" /><input type="hidden" value="1" name="embed" /><input type="submit" value="Subscribe" style={{
                        borderRadius: 4
                    }} /></p></form>
                <DisplayTag clickHandler={handleClick} group={group} />
                <WorksSection edges={arr} />
            </Layout>
        </div>
    )
}


TagsPage.propTypes = {
    data: PropTypes.shape({
        allWorksYaml: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                })
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default TagsPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allWorksYaml {
            group(field: tags) {
                fieldValue
                totalCount
            }            
            edges {
                node {
                    title
                    tags
                    thumbnail {
                        id
                        childImageSharp {
                          fluid {
                            originalImg
                          }
                        }
                      }                    
                    image {
                        id
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid
                            originalImg
                        }
                    }                  
                }
            }
        }
    }
}`
