const carouselFormatters = {
    getAltText: ({ data, index }: { data: { caption: string }; index: number }) =>
        data.caption || `${index + 1}. picture`,

    getNextLabel: ({
        currentIndex,
        views,
    }: {
        currentIndex: number;
        views: { [key: string]: any }[];
    }) => `Show ${currentIndex + 2}. picture ${views.length} of`,
    getPrevLabel: ({
        currentIndex,
        views,
    }: {
        currentIndex: number;
        views: { [key: string]: any }[];
    }) => `Show ${currentIndex}. picture ${views.length} of`,

    getNextTitle: () => 'Next (right arrow)',
    getPrevTitle: () => 'Previous (left arrow)',

    getCloseLabel: () => 'Press ESC',
    getFullscreenLabel: ({ isFullscreen }: { isFullscreen: boolean }) =>
        isFullscreen
            ? 'Exit full screen view(f)'
            : 'Switch to full screen (f)',
};

export default carouselFormatters;
